// CSS Files
import "../css/f-starter/bootstrap-social.scss";
import 'font-awesome/css/font-awesome.css';
import 'admin-lte/plugins/select2/css/select2.css';
import '../css/f-starter/form_standard_style.scss';
import '../css/f-security/security.scss';
import '../css/f-security/registration.scss';

// JS Files
const $ = require('jquery');
Window.prototype.$ = $; //HERE IS MY SOLUTION (Without this line it doesn't work!)

import '@popperjs/core';
import 'admin-lte/dist/js/adminlte';
import 'admin-lte/plugins/bootstrap/js/bootstrap';
import 'bootstrap/js/src/dropdown';
import 'admin-lte/plugins/select2/js/select2.full';
import 'slick-carousel/slick/slick';
import 'moment/moment';
import 'admin-lte/plugins/inputmask/jquery.inputmask';
import './f-starter/disableIntegerInputScroll';
import {initRegisterEditUserForm, validatePassword, passwordChecker} from "./f-security/register_edit_user_form_type";

$(document).ready(function () {

    let $registration_country = $('#registration_country');
    let $registration_province = $('#registration_province');
    let $registration_gender = $('#registration_gender');
    let $registration_dateOfBirth = $('#registration_dateOfBirth');
    let $registration_password = $('#registration_password');
    let $registration_repeatPassword = $('#registration_repeatPassword');

    $registration_dateOfBirth.inputmask({
        placeholder: 'dd/mm/aaaa'
    });

    initRegisterEditUserForm($registration_country, $registration_province, $registration_gender);
    passwordChecker($registration_password, $registration_repeatPassword);

    $registration_dateOfBirth.attr('placeholder', 'Fecha Nacimiento');

    if ($('.route_name').attr('id') === 'app_password_reset_email_verification') {
        let $password = $('#password_reset_password');
        let $repeatPassword = $('#password_reset_repeatPassword');
        passwordChecker($password, $repeatPassword);
    }
});
