/*================================================
Eliminamos el sroll en los input de tipo numérico.
================================================*/
export default function disableIntegerInputScroll() {
    $('form').on('focus', 'input[type=number]', function (e) {
        $(this).on('wheel.disableScroll', function (e) {
            e.preventDefault();
        })
    });
    $('form').on('blur', 'input[type=number]', function (e) {
        $(this).off('wheel.disableScroll');
    });
}