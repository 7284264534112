function initRegisterEditUserForm(country, province, gender) {
    $(country).select2({
        placeholder: "País de residencia",
        allowClear: true,
        language: {
            noResults: () => "No se han encontrado resultados"
        }
    });
    $(province).select2({
        placeholder: "Provincia",
        allowClear: true,
        language: {
            noResults: () => "No se han encontrado resultados"
        }
    });
    $(gender).select2({
        placeholder: "Género",
        allowClear: true,
        language: {
            noResults: () => "No se han encontrado resultados"
        }
    });

    $(country).val() === 'ES' ? $(province).parent().show() : $(province).parent().hide();
    $(country).change(function () {
        $(country).val() === 'ES' ? $(province).parent().show() : $(province).parent().hide();
    });
}

function passwordChecker(password, repeatPassword) {
    /*======================================
    Seteamos las validaciones de contraseña.
    ====================================*=*/
    let minLength = 6;
    let containsUppercase = /[A-Z]/;
    let containsLowercase = /[a-z]/;
    let containsNumbers = /[0-9]/;

    /*==================================
    Recogemos los elementos pertinentes.
    =================================**/
    let $password_requirements = $('.password_requirements');
    let $same_password = $('.same_password_requirement');
    let $min_length = $('.min_length_requirement');
    let $contains_lowercase = $('.contains_lowercase_requirement');
    let $contains_uppercase = $('.contains_uppercase_requirement');
    let $contains_number = $('.contains_number_requirement');

    /*====================================================================
    Creamos los eventos para los campos de contraseña y repetir contraseña.
    =====================================================================*/
    $(password).focus(function () {
        $password_requirements.fadeIn();
        $(password).siblings().remove();
        $(repeatPassword).siblings().remove();
    });
    $(password).keyup(function () {
        let $value = $(this).val();
        validatePassword($value.length >= minLength, $min_length);
        validatePassword(containsLowercase.test($value), $contains_lowercase);
        validatePassword(containsUppercase.test($value), $contains_uppercase);
        validatePassword(containsNumbers.test($value), $contains_number);
        validatePassword($value === $(repeatPassword).val(), $same_password);

        if ($password_requirements.find('.fa-times').length === 0) {
            $password_requirements.fadeOut();
        } else {
            $password_requirements.fadeIn();
        }
    });
    $(password).blur(function () {
        $password_requirements.fadeOut();
    });

    $(repeatPassword).focus(function () {
        $password_requirements.fadeIn();
        $(password).siblings().remove();
        $(repeatPassword).siblings().remove();
    });
    $(repeatPassword).keyup(function () {
        let $value = $(this).val();
        validatePassword($value === $(password).val(), $same_password);

        if ($password_requirements.find('.fa-times').length === 0) {
            $password_requirements.fadeOut();
        } else {
            $password_requirements.fadeIn();
        }
    });
    $(repeatPassword).blur(function () {
        $password_requirements.fadeOut();
    });
}

function validatePassword(testPassed, testName) {
    if (testPassed) {
        testName.attr('style', 'color: #0b6d0b');
        testName.find('i').removeClass('fa-times');
        testName.find('i').addClass('fa-check');
        testName.find('i').attr('style', 'color: #0b6d0b');
    } else {
        testName.attr('style', 'color: red');
        testName.find('i').removeClass('fa-check');
        testName.find('i').addClass('fa-times');
        testName.find('i').attr('style', 'color: red');
    }
}

export {initRegisterEditUserForm, passwordChecker, validatePassword}